<template>
  <section class="statisticsRooms">
    <FilterBarStatistics />
    <PercentageBar />
    <div :class="`statisticsRooms__${isMinWidth ? 'container' : 'contentRanking'}`">
      <AverageOccupancyComlumns
        :description="'Promedio ocupación de habitaciones por sedes'"
        :keyData="'occupancyOffices'"
        :data="occupancyOffices"
        v-if="queryParts.includes('city') && !queryParts.includes('office')"
      />
      <AverageOccupancyCities v-else-if="isGeneralData" />
      <div :class="`statisticsRooms__${isOfficeData && isMinWidth ? 'container' : 'contentRanking'}`">
        <RankingCardContent :description="'Ranking de habitaciones con mayor ocupación'" :component="'CardRankingRoom'" />
        <RankingCardContent :description="'Ranking de Modelos'" :component="'CardRankingModel'" />
      </div>
    </div>
    <OccupancyRoomsGeneral v-if="isGeneralData" />
    <AverageOccupancyComlumns
      :description="'Ocupación de habitaciones general'"
      :keyData="'hoursInRooms'"
      :data="hoursInRooms"
      v-else-if="isOfficeData"
    />
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { socket } from "@/socket";
export default {
  components: {
    FilterBarStatistics: () => import("./FilterBarStatistics.vue"),
    PercentageBar: () => import("./PercentageBar.vue"),
    RankingCardContent: () => import("./RankingCardContent.vue"),
    AverageOccupancyCities: () => import("./AverageOccupancyCities.vue"),
    OccupancyRoomsGeneral: () => import("./OccupancyRoomsGeneral.vue"),
    AverageOccupancyComlumns: () => import("./AverageOccupancyComlumns.vue"),
  },
  computed: {
    ...mapGetters("Occupation", ["occupancyOffices", "hoursInRooms"]),
    query() {
      return this.$route.fullPath.split("?")[1];
    },
    queryParts() {
      const query = this.$route.query;
      return Object.keys(query);
    },
    isGeneralData() {
      return !this.queryParts.includes("city") && !this.queryParts.includes("office");
    },
    isOfficeData() {
      return this.queryParts.includes("city") && this.queryParts.includes("office");
    },
    isMinWidth() {
      return this.$screen.width > 1350;
    },
  },
  watch: {
    $route() {
      this.getStatistics(this.query);
    },
  },
  methods: {
    ...mapActions("Occupation", ["getStatistics"]),
  },
  async beforeMount() {
    socket.on("updateStatistics", ({ eventUpdate, data }) => {
      this.$store.commit(`Occupation/${eventUpdate}`, data);
    });

    if (this.query) {
      this.getStatistics(this.query);
    }
  },
};
</script>

<style lang="scss">
.statisticsRooms {
  @include Flex(column, flex-start);
  gap: $mpadding;
  width: 100%;
  max-width: $tv_width;
  &__contentRanking {
    @include Flex(row, flex-start);
    flex-wrap: wrap;
    width: 100%;
    gap: $mpadding;
  }
  &__container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(650px, 1fr));
    grid-auto-rows: auto;
    width: 100%;
    gap: $mpadding;
  }
}
</style>
